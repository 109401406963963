function init() {
  let items = document.getElementsByClassName("faq-item");
  for (let i = 0; i < items.length; i++) {
    const item = items.item(i);
    item.addEventListener("click", function(element) {
      if (!item.classList.contains("open")) {
        for (let i = 0; i < items.length; i++) {
          const element = items.item(i);
          if (element.classList.contains("open")) {
            faqClose(element);
          }
        }
        faqOpen(item);
      }
    });
  }
}

function faqOpen(item) {
  item.classList.add("open");
  item.querySelector("span").innerHTML = "-";
}

function faqClose(item) {
  item.classList.remove("open");
  item.querySelector("span").innerHTML = "+";
}

window.addEventListener("load", async () => {
  init();
});